import React from 'react'
import {Route} from 'react-router-dom'
import Parameters from './Parameters'
import RunSimulation from './RunSimulation'

const Simulation = () => {
  return (
    <>
      <Route exact path="/simulation" component={Parameters} />
      <Route exact path="/simulation/run" component={RunSimulation} />
    </>
  )
}

export default Simulation
