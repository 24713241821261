import {IParameters} from '../types/simulation'

const API_ROOT = '/'

function callApi(endpoint: string) {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint

  return fetch(fullUrl)
    .then(response => response.json().then(json => ({json, response})))
    .then(({json, response}) => {
      if (!response.ok) {
        return Promise.reject(json)
      }
      return json
    })
}

export const fetchSimulation = ({l, r, e, a, k}: IParameters) =>
  callApi(`/jsons/L${l}R${r}E${e}A${a}K${k.toFixed(2)}.json`)
