import {IData} from '../../types/exploration'

export const data = ({
  'Average Occupancy': {
    min: 1.61,
    max: 4.97,
    data: [
      [1.61, 1.73, 1.77, 1.79],
      [2.3, 2.51, 2.57, 2.58],
      [3.01, 3.29, 3.36, 3.38],
      [3.72, 4.08, 4.16, 4.17],
      [4.43, 4.88, 4.96, 4.97],
    ],
  },
  'Average In-Car Delay': {
    min: 9.91,
    max: 220.95,
    data: [
      [9.91, 36.52, 52.47, 73.23],
      [16.56, 55.99, 81.91, 120.72],
      [21.36, 71, 102.22, 157.91],
      [26.07, 82.54, 117.03, 189.76],
      [30.09, 93.05, 132.68, 220.95],
    ],
  },
  'CO₂ Reduction': {
    min: 11.88,
    max: 107.18,
    data: [
      [11.88, 16.6, 17.65, 17.86],
      [29.2, 36.72, 38.46, 39.32],
      [47.91, 57.58, 60.11, 61.5],
      [67.65, 78.86, 82.41, 84.3],
      [87.45, 100.51, 104.71, 107.18],
    ],
  },
  'Distance Reduction': {
    min: 7.85,
    max: 70.78,
    data: [
      [7.85, 10.97, 11.65, 11.79],
      [19.29, 24.25, 25.4, 25.96],
      [31.64, 38.03, 39.69, 40.61],
      [44.68, 52.08, 54.42, 55.67],
      [57.75, 66.37, 69.15, 70.78],
    ],
  },
  'Number of Trips Reduction': {
    min: 15.08,
    max: 80.08,
    data: [
      [15.08, 15.56, 15.77, 15.82],
      [30.62, 31.49, 31.76, 31.82],
      [46.22, 47.48, 47.78, 47.83],
      [61.85, 63.51, 63.84, 63.88],
      [77.65, 79.68, 80.04, 80.08],
    ],
  },
} as unknown) as IData
