import React, { CSSProperties } from "react";
import styles from './Footer.module.scss'
import mariecurie from '../../assets/mariecurie.png'
import iiia from '../../assets/iiia.png'
import csic from '../../assets/csic.png'
//import verona from '../../assets/verona.png'

const vertCenter: CSSProperties = {
  display: 'flex',
  alignItems: 'center'
}
const justifyLeft: CSSProperties = {
  textAlign: 'left' as const
}
interface Props {
  className?: string
}
const Footer = ({className}: Props) => (
  <footer className={`${className} ${styles.footer}`}>
    <div style={justifyLeft}>
      Based on the article <a href="https://doi.org/10.1109/TITS.2019.2954982" target="_blank" rel="noopener noreferrer">
        A Computational Approach to Quantify the Benefits of Ridesharing for Policy Makers and Travellers
      </a><br />
      <i>Published in IEEE Transactions on Intelligent Transportation Systems 22(1), 2021</i>
    </div>
    <div style={vertCenter}>
      <a href="https://filippobistaffa.github.io/HPA4CF/index.html" target="_blank" rel="noopener noreferrer">
        <img src={mariecurie} alt="Marie Curie Actions" />
      </a>
      <a href="http://www.iiia.csic.es" target="_blank" rel="noopener noreferrer">
        <img src={iiia} alt="Institut d'Investigació en Intel·ligència Artifical (IIIA-CSIC)" />
      </a>
      <a href="https://www.csic.es" target="_blank" rel="noopener noreferrer">
        <img src={csic} alt="Consejo Superior de Investigaciones Científicas (CSIC)" />
      </a>
    </div>
  </footer>
)

export default Footer
