import {simulationReducer} from './simulation/reducers'
import {combineReducers} from 'redux'
import saga from './simulation/sagas'
import simulationResultsSaga from './simulationResults/sagas'
import {all} from 'redux-saga/effects'
import simulationResultsReducer from './simulationResults/reducers'

export const rootReducer = combineReducers({
  simulation: simulationReducer,
  sim1: simulationResultsReducer('sim1'),
  sim2: simulationResultsReducer('sim2'),
  sim3: simulationResultsReducer('sim3'),
})

export function* rootSaga() {
  yield all([
    saga(),
    simulationResultsSaga('sim1')(),
    simulationResultsSaga('sim2')(),
    simulationResultsSaga('sim3')(),
  ])
}

export type AppState = ReturnType<typeof rootReducer>
