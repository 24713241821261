import React from 'react'
import styles from './LoadingIcon.module.scss'
import {ReactComponent as Icon} from '../../assets/loading.svg'

interface FixedWidth {
  mode: 'fixed'
  loading: boolean
}
interface NonFixedWidth {
  mode: 'normal'
}
type Props = FixedWidth | NonFixedWidth

const defaultProps = {
  mode: 'normal' as 'normal',
}

const LoadingIcon = (props: Props = defaultProps) => {
  if (props.mode === 'normal') {
    return <Icon className={styles.icon} />
  }
  return (
    <div className={styles.fw}>
      {props.loading && <Icon className={styles.icon} />}
    </div>
  )
}

export default LoadingIcon
