import {ISimulation, IParameter, IParameters} from '../../types/simulation'
import {
  LOAD,
  LOAD_DONE,
  LOAD_ERROR,
  SET_PARAMETER,
  SET_STEP,
  SimulationActionTypes,
} from './types'

export function setParameter(
  parameter: IParameter,
  value: number
): SimulationActionTypes {
  return {
    type: SET_PARAMETER,
    parameter,
    value,
  }
}

export function setStep(step: number): SimulationActionTypes {
  return {
    type: SET_STEP,
    step,
  }
}

export function load(parameters: IParameters): SimulationActionTypes {
  return {
    type: LOAD,
    parameters,
  }
}

export function loadDone(data: ISimulation): SimulationActionTypes {
  return {
    type: LOAD_DONE,
    data,
  }
}

export function loadError(): SimulationActionTypes {
  return {
    type: LOAD_ERROR,
  }
}
