import React from 'react'
import {connect} from 'react-redux'
import Footer from '../../components/Footer/Footer'
import Menu from '../../components/Menu/Menu'
import PageHeader from '../../components/PageHeader/PageHeader'
import ParameterSlider from '../../components/Simulation/Parameters/ParameterSlider'
import SimulationGraphs from '../../components/SimulationGraphs/SimulationGraphs'
import {AppState} from '../../store'
import {ISimulationState} from '../../store/simulation/types'
import styles from './RunSimulation.module.scss'

// import MapSection from '../../components/Map/MapSection'
import {lazy, Suspense} from "react";
const MapSection = lazy(() => import ('../../components/Map/MapSection'));

type RunSimulationProps = Pick<ISimulationState, 'simulation' | 'status'>
const RunSimulation = ({simulation, status}: RunSimulationProps) => {
  return (
    <div className={styles.wrapper}>
      <PageHeader variant="light" />
      <Menu />
      <div className={styles.hero}>
        <div>
          <h1>Simulation</h1>
        </div>
      </div>
      <div className={styles.parameters}>
        <div>
          <div>
            <div>Adopt. rate</div>
            <ParameterSlider tipFormatter={(t: number) => t + '%'} name="a" />
          </div>
          <div>
            <div>Env. benefit importance</div>
            <ParameterSlider
              tipFormatter={(t: number) => t * 100 + '%'}
              name="k"
            />
          </div>
        </div>
      </div>
      <section className={styles.page}>
        <div className={styles.page_inner}>
          {status === 'error' && (
            <div className={styles.error}>Simulation failed :(</div>
          )}
          {status !== 'error' && simulation !== null && (
            <>
              <SimulationGraphs
                className={styles.graphs}
                simulation={simulation}
              />
              <Suspense fallback={<div style={{height:'500px'}}>Loading MapSection...</div>}>
                <MapSection simulation={simulation} />
              </Suspense>
            </>
          )}
        </div>
      </section>
      <Footer className={styles.footer} />
    </div>
  )
}

export default connect((state: AppState) => ({
  simulation: state.simulation.simulation,
  status: state.simulation.status,
}))(RunSimulation)
