import {ISimulationResultsState, Actions, SimulationActionTypes} from './types'

const initialState: ISimulationResultsState = {
  status: 'initial',
  simulation: null,
}

function reducer(
  state = initialState,
  action: SimulationActionTypes
): ISimulationResultsState {
  switch (action.type) {
    case Actions.LOAD:
      return {
        ...state,
        status: 'loading',
      }

    case Actions.LOAD_DONE:
      return {
        ...state,
        status: 'loaded',
        simulation: action.data,
      }

    case Actions.LOAD_ERROR:
      return {
        ...state,
        status: 'error',
      }

    default:
      return state
  }
}

export default function simulationResultsReducer(namespace: string) {
  const mapping: {[key: string]: string} = {}
  for (const act in Actions) {
    mapping[`${Actions[act]}:${namespace}`] = Actions[act]
  }

  return (state: any, action: SimulationActionTypes) => {
    if (action.type in mapping) {
      return reducer(state, {
        ...action,
        type: mapping[action.type],
      } as SimulationActionTypes)
    } else {
      return reducer(state, action)
    }
  }
}
