import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {ReactComponent as IconResults1} from '../../assets/icon-results-1.svg'
import {ReactComponent as IconResults2} from '../../assets/icon-results-2.svg'
import {ReactComponent as IconResults3} from '../../assets/icon-results-3.svg'
import Footer from '../../components/Footer/Footer'
import Menu from '../../components/Menu/Menu'
import PageHeader from '../../components/PageHeader/PageHeader'
import SimulationGraphs from '../../components/SimulationGraphs/SimulationGraphs'
import {AnalysisParameters, ParameterNames} from '../../config'
import {AppState} from '../../store'
import {namespaceCreators} from '../../store/namespaces'
import {load} from '../../store/simulationResults/actions'
import {ISimulationResultsState} from '../../store/simulationResults/types'
import {IParameters} from '../../types/simulation'
import styles from './Summary.module.scss'

const Parameters = ({parameters}: {parameters: IParameters}) => (
  <div className={styles.parameters}>
    <dl>
      <dt>{parameters.k * 100}%</dt>
      <dd>{ParameterNames.k}</dd>
      <dt>{parameters.a}%</dt>
      <dd>{ParameterNames.a}</dd>
    </dl>
  </div>
)

interface Props {
  sim1: ISimulationResultsState
  sim2: ISimulationResultsState
  sim3: ISimulationResultsState
  load1: (parameters: IParameters) => void
  load2: (parameters: IParameters) => void
  load3: (parameters: IParameters) => void
}
const Summary = ({sim1, sim2, sim3, load1, load2, load3}: Props) => {
  useEffect(() => {
    if (sim1.status === 'initial') load1(AnalysisParameters[1])
    if (sim2.status === 'initial') load2(AnalysisParameters[2])
    if (sim3.status === 'initial') load3(AnalysisParameters[3])
    // Disable complain about missing dependencies:
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.wrapper}>
      <PageHeader variant="light" />
      <Menu />
      <div className={styles.hero}>
        <div>
          <h1>Analysis</h1>
          <p>Results for 3 different ridesharing adoption policies over one day (3 December 2016) using actual-world data from the <a href="https://www1.nyc.gov/site/tlc/about/tlc-trip-record-data.page" target="_blank" rel="noopener noreferrer">New York City TLC dataset</a></p>
        </div>
      </div>
      <section className={styles.page}>
        <div className={styles.page_inner}>
          <div className={styles.columns}>
            <div className={styles.column}>
              <IconResults1 />
              <h3>Focus on Environmental Benefits</h3>
              {sim1.status === 'loaded' && sim1.simulation && (
                <>
                  <SimulationGraphs
                    className={styles.graphs}
                    simulation={sim1.simulation}
                  />
                  <Parameters parameters={AnalysisParameters[1]} />
                </>
              )}
            </div>
            <div className={styles.column}>
              <IconResults2 />
              <h3>Balanced</h3>
              {sim2.status === 'loaded' && sim2.simulation && (
                <>
                  <SimulationGraphs
                    className={styles.graphs}
                    simulation={sim2.simulation}
                  />
                  <Parameters parameters={AnalysisParameters[2]} />
                </>
              )}
            </div>
            <div className={styles.column}>
              <IconResults3 />
              <h3>Focus on Quality of Service</h3>
              {sim3.status === 'loaded' && sim3.simulation && (
                <>
                  <SimulationGraphs
                    className={styles.graphs}
                    simulation={sim3.simulation}
                  />
                  <Parameters parameters={AnalysisParameters[3]} />
                </>
              )}
            </div>
          </div>

          <div className={styles.explore}>
            <h3>Explore a custom scenario</h3>
            <p>Create a custom scenario by setting a personalised objective (in terms of CO₂ reduction, trips reduction, in-car delay, etc) and explore the feasibility zone in terms of adoption rate and/or environmental benefit importance required to achieve such objective</p>
            <div className={styles.actions}>
              <Link to="/analysis/explore" className={styles.explore_button}>
                Explore
              </Link>
            </div>
          </div>
        </div>
      </section>
      <Footer className={styles.footer} />
    </div>
  )
}

export default connect(
  (state: AppState) => ({
    sim1: state.sim1,
    sim2: state.sim2,
    sim3: state.sim3,
  }),
  (dispatch, ownProps: any) => {
    const [load1] = namespaceCreators('sim1', [load])
    const [load2] = namespaceCreators('sim2', [load])
    const [load3] = namespaceCreators('sim3', [load])
    return {
      load1: (parameters: IParameters) => dispatch(load1(parameters)),
      load2: (parameters: IParameters) => dispatch(load2(parameters)),
      load3: (parameters: IParameters) => dispatch(load3(parameters)),
    }
  }
)(Summary)
