import React from 'react'
import styles from './PageHeader.module.scss'

interface Props {
  variant: 'dark' | 'light'
  color?: string
}

const PageHeader = ({variant, color}: Props) => (
  <header
    className={
      styles.header + ' ' + (variant === 'dark' ? styles.dark : styles.light)
    }
  >
    <p>
      <a href="https://filippobistaffa.github.io/HPA4CF/index.html" target="_blank" rel="noopener noreferrer">
        Highly-parallel Algorithms for Collective Intelligence
      </a>
    </p>
  </header>
)

export default PageHeader
