import {ISimulation, IParameters, IParameter} from '../../types/simulation'

export const SET_PARAMETER = 'SIMULATION:SET_PARAMETER'
export const SET_STEP = 'SIMULATION:SET_STEP'
export const LOAD = 'SIMULATION:LOAD'
export const LOAD_DONE = 'SIMULATION:LOAD:DONE'
export const LOAD_ERROR = 'SIMULATION:LOAD:ERROR'

export interface ISimulationState {
  status: 'initial' | 'loading' | 'error' | 'loaded'
  simulation: ISimulation | null
  parameters: IParameters
}

export interface SetParameterAction {
  type: typeof SET_PARAMETER
  parameter: IParameter
  value: number
}

export interface SetStepAction {
  type: typeof SET_STEP
  step: number
}

export interface LoadAction {
  type: typeof LOAD
  parameters: IParameters
}

export interface LoadDoneAction {
  type: typeof LOAD_DONE
  data: ISimulation
}

export interface LoadErrorAction {
  type: typeof LOAD_ERROR
}

export type SimulationActionTypes =
  | SetParameterAction
  | SetStepAction
  | LoadAction
  | LoadDoneAction
  | LoadErrorAction
