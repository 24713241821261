import React from 'react'
import {SliderProps, WithTooltipProps} from 'rc-slider'
import Slider from '../../Slider/Slider'
import {connect} from 'react-redux'
import {AppState} from '../../../store'
import {setParameter} from '../../../store/simulation/actions'
import {IParameter} from '../../../types/simulation'
import {PossibleParameters} from '../../../config'

interface OwnProps {
  name: IParameter
}
interface ConnectedProps {
  value: number
}
type Props = OwnProps & ConnectedProps & SliderProps & WithTooltipProps
const ParameterSlider = ({name, ...props}: Props) => {
  const values = PossibleParameters[name]
  return (
    <Slider
      {...props}
      tipProps={{
        visible: true,
      }}
      included={false}
      min={values[0]}
      max={values[values.length - 1]}
      marks={values.reduce(
        (map, v) => {
          map[v as number] = ''
          return map
        },
        {} as any
      )}
      step={null}
    />
  )
}

export default connect(
  (state: AppState, ownProps: OwnProps): ConnectedProps => ({
    value: state.simulation.parameters[ownProps.name],
  }),
  (dispatch, ownProps: OwnProps) => ({
    onChange: (value: number) => dispatch(setParameter(ownProps.name, value)),
  })
)(ParameterSlider)
