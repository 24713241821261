import {
  ISimulationState,
  LOAD,
  LOAD_DONE,
  LOAD_ERROR,
  SET_PARAMETER,
  SimulationActionTypes,
} from './types'
import {InitialSimulationParameters} from '../../config'

const initialState: ISimulationState = {
  status: 'initial',
  simulation: null,
  parameters: InitialSimulationParameters,
}

export function simulationReducer(
  state = initialState,
  action: SimulationActionTypes
): ISimulationState {
  switch (action.type) {
    case SET_PARAMETER:
      return {
        ...state,
        parameters: {
          ...state.parameters,
          [action.parameter]: action.value,
        },
      }

    case LOAD:
      return {
        ...state,
        status: 'loading',
      }

    case LOAD_DONE:
      return {
        ...state,
        status: 'loaded',
        simulation: action.data,
      }

    case LOAD_ERROR:
      return {
        ...state,
        status: 'error',
      }

    default:
      return state
  }
}
