import React from 'react'
import {Link} from 'react-router-dom'
import styles from './Home.module.scss'
import Menu from '../components/Menu/Menu'
import PageHeader from '../components/PageHeader/PageHeader'
import Footer from '../components/Footer/Footer'

const Home = () => (
  <div className={styles.wrapper}>
    <div className={styles.left}>
      <PageHeader variant="dark" />
      <section className={`${styles.section} ${styles.simulation}`}>
        <div>
          <h2>Simulation</h2>
          <p className={styles.callout}>
            Simulate a ridesharing scenario and show the resulting benefits
          </p>
          <p>
            Simulate a ridesharing scenario according to your parameters (simulated data will follow the distribution of the <a href="https://www1.nyc.gov/site/tlc/about/tlc-trip-record-data.page" target="_blank" rel="noopener noreferrer">New York City TLC dataset</a>), and visualise the resulting environmental benefits and quality of service
          </p>
          <Link to="/simulation" className={styles.button_light}>
            Simulate
          </Link>
        </div>
      </section>
    </div>
    <div className={styles.right}>
      <Menu />

      <section className={`${styles.section} ${styles.analysis}`}>
        <div>
          <h2>Analysis</h2>
          <p className={styles.callout}>
            Explore and compare different ridesharing adoption policies
          </p>
          <p>
            Show the results for different ridesharing adoption policies over one day (3 December 2016) using actual-world data from the <a href="https://www1.nyc.gov/site/tlc/about/tlc-trip-record-data.page" target="_blank" rel="noopener noreferrer">New York City TLC dataset</a>, or explore your own customised ridesharing policy
          </p>
          <Link to="/analysis" className={styles.button_dark}>
            Analyse
          </Link>
        </div>
      </section>
    </div>

    <Footer className={styles.footer} />
  </div>
)

export default Home
