import React, {useMemo, useState} from 'react'
import DonutChart from '../../components/DonutChart/DonutChart'
import {Color} from 'csstype'
import styles from './SimulationGraphs.module.scss'
import Gauge from '../Gauge/Gauge'
import {ISimulation} from '../../types/simulation'

interface GraphProps {
  title: string
  percent?: number
  value: number
  unit: string
  color: Color
}
const Graph = ({title, percent, value, unit, color}: GraphProps) => (
  <div className={`${styles.graph} graph`}>
    <div className={styles.graph_container}>
      <div style={{color}} className={styles.graph_data}>
        {percent ? (
          <>
            <div>
              <b>-{percent}%</b>
            </div>
            -{value}
            {unit}
          </>
        ) : (
          <b>
            {value}
            {unit}
          </b>
        )}
      </div>
      <Gauge
        className={styles.gauge}
        color={color}
        background="#ccc"
        value={percent ? percent / 100.0 : Math.min(value / 700.0, 0.99)}
      />
    </div>
    <p>{title}</p>
  </div>
)

interface CarGraphProps {
  title: string
  frequencies: {[passengers: number]: number}
}
const CarGraph = ({frequencies, title}: CarGraphProps) => {
  const [data, total] = useMemo(() => {
    const data: any = []
    let total = 0
    Object.keys(frequencies).forEach(k => {
      const nCars = parseInt(k)
      const value = frequencies[nCars] || 0
      total += value
      data.push({
        value,
        label: nCars,
      })
    })
    return [data, total]
  }, [frequencies])
  const [selected, select] = useState(null as Item | null)

  return (
    <div className={`${styles.graph} graph`}>
      <div className={styles.graph_container}>
        <div className={styles.graph_data}>
          {selected ? (
            <>
              <div>{selected.label} pax.</div>
              <div>
                <b>{((selected.value / total) * 100.0).toFixed(0)}%</b>
              </div>
              <div>{selected.value} cars</div>
            </>
          ) : (
            <>
              <div>{total}</div>
              cars
            </>
          )}
        </div>
        <DonutChart
          className={styles.donut}
          data={data}
          strokeColor="rgba(0,0,0,0)"
          onMouseEnter={item => select(item)}
          onMouseLeave={item => select(null)}
        />
      </div>
      <p>{title}</p>
    </div>
  )
}

interface Props {
  simulation: ISimulation
  className?: string
}

const SimulationGraphs = ({simulation, className}: Props) => (
  <div className={`${styles.graphs} ${className}`}>
    <Graph
      color="#466587"
      title="CO₂ Emissions Reduction"
      percent={simulation.dist_red_perc}
      value={simulation.kg_co2_red > 1000 ?
             Math.round(simulation.kg_co2_red / 1000) :
             simulation.kg_co2_red}
      unit={simulation.kg_co2_red > 1000 ? " Tons" : " Kg"}
    />
    <Graph
      color="#7ebb30"
      title="Number of Trips Reduction"
      percent={simulation.trip_red_perc}
      value={simulation.trip_red}
      unit=" trips"
    />
    <CarGraph title="Formed Cars" frequencies={simulation.formed_cars} />
    <Graph
      color="#da2c32"
      title="Average In-Car Delay"
      value={simulation.icd_mean}
      unit="s"
    />
  </div>
)

export default SimulationGraphs
