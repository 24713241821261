export enum Target {
  average_occupancy = 'Average Occupancy',
  time_delay = 'Average In-Car Delay',
  co2_reduction = 'CO₂ Reduction',
  distance_reduction = 'Distance Reduction',
  trip_reduction = 'Number of Trips Reduction',
}

export interface ITargetData {
  min: number
  max: number
  data: number[][]
}

export type IData = {[k in Target]: ITargetData}
