import React from 'react'
import {BrowserRouter as Router, Route} from 'react-router-dom'

import Home from './pages/Home'
import Analysis from './pages/Analysis/Analysis'
import Simulation from './pages/Simulation/Simulation'

import ReactGA from 'react-ga'
ReactGA.initialize('UA-73960040-2')
ReactGA.pageview(window.location.pathname + window.location.search);

export default () => (
  <Router>
    <Route exact path="/" component={Home} />
    <Route path="/analysis" component={Analysis} />
    <Route path="/simulation" component={Simulation} />
  </Router>
)
