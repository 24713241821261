import {Color} from 'csstype'
import React, {Component} from 'react'
import ArcPath from './ArcPath'

const defaultProps = {
  toggleSelect: false,
  total: 100,
  className: 'donutchart-arcs',
  width: 500,
  colors: [
    '#f44336',
    '#e91e63',
    '#9c27b0',
    '#673ab7',
    '#3f51b5',
    '#2196f3',
    '#03a9f4',
    '#00bcd4',
    '#009688',
    '#4caf50',
    '#8bc34a',
    '#cddc39',
    '#ffeb3b',
    '#ffc107',
    '#ff9800',
    '#ff5722',
    '#795548',
    '#607d8b',
  ] as Color[],
  strokeColor: '#212121' as Color,
  startAngle: 0,
  colorFunction: (colors: Color[], index: number) =>
    colors[index % colors.length],
  innerRadius: 0.7,
  outerRadius: 0.9,
  selectedOffset: 0.03,
  toggledOffset: 0.04,
}

type DefaultProps = Readonly<typeof defaultProps>

type Props = {
  data: Item[]
  selected: Item | null
  onMouseEnter: (item: Item) => void
  onMouseLeave: (item: Item) => void
  onClick: (item: Item) => void
} & Partial<DefaultProps>

/**
 * @extends {Component}
 */
export default class Arcs extends Component<Props & DefaultProps> {
  static defaultProps = defaultProps

  render() {
    const {
      startAngle,
      className,
      data,
      selectedOffset,
      toggledOffset,
      colors,
      strokeColor,
      colorFunction,
      onMouseEnter,
      onMouseLeave,
      onClick,
      total,
      width,
      selected,
      toggleSelect,
    } = this.props
    const arcPathClassName = `${className}-path`
    let angle = startAngle

    return (
      <g className={className}>
        {data.map((item, index) => {
          const classes: {[k: string]: boolean} = {}
          const {value, className} = item

          let {innerRadius, outerRadius} = this.props
          let stroke = strokeColor
          let opacity = 1

          if (selected && selected.label === item.label) {
            if (toggleSelect) {
              classes.toggled = true
              innerRadius -= toggledOffset
              outerRadius += toggledOffset
              opacity = 1
            } else {
              classes.selected = true
              outerRadius += selectedOffset
              opacity = 0.5
            }
          }

          if (className) {
            classes[className] = true
          }
          classes[arcPathClassName] = true

          const fill = colorFunction(colors, index)

          const arcPath = (
            <ArcPath
              width={width}
              item={item}
              key={`arcpath${index}`}
              innerRadius={innerRadius}
              outerRadius={outerRadius}
              className={'arc'}
              opacity={opacity}
              fill={fill}
              stroke={stroke}
              angle={angle}
              total={total}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={onClick}
            />
          )

          angle += (value / total) * 360

          return arcPath
        })}
      </g>
    )
  }
}
