import {call, put, takeLatest, all, select} from 'redux-saga/effects'
import {LOAD, SET_PARAMETER, LoadAction} from './types'
import {loadDone, loadError, load} from './actions'
import {fetchSimulation} from '../../services/api'
import {AppState} from '..'

function* loadSimulation(action: LoadAction) {
  try {
    const simulation = yield call(fetchSimulation, action.parameters)
    yield put(loadDone(simulation))
  } catch (e) {
    yield put(loadError())
  }
}

function* reloadSimulation() {
  const parameters = yield select(
    (state: AppState) => state.simulation.parameters
  )
  yield put(load(parameters))
}

function* saga() {
  yield all([
    reloadSimulation(),
    takeLatest(SET_PARAMETER, reloadSimulation),
    takeLatest(LOAD, loadSimulation),
  ])
}

export default saga
