import React, {Component} from 'react'
import {Color} from 'csstype'

/**
 * Calculate coordinates of given arc.
 * @param {} half - Half the total width of the chart.
 * @param {} radius - Radius of the arc.
 * @param {} startAngle - Starting angle for the arc.
 * @param {} endAngle - Ending angle for the arc.
 */
function coordinates(
  half: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  const startAngleDegrees = (Math.PI * startAngle) / 180
  const x1 = half + half * radius * Math.cos(startAngleDegrees)
  const y1 = half + half * radius * Math.sin(startAngleDegrees)
  const endAngleDegrees = (Math.PI * endAngle) / 180
  const x2 = half + half * radius * Math.cos(endAngleDegrees)
  const y2 = half + half * radius * Math.sin(endAngleDegrees)

  return {
    x1,
    y1,
    x2,
    y2,
  }
}

/**
 * Creates an SVG arc object for an SVG path object.
 * @param {} width - Total width of the chart.
 * @param {} radius - Radius of the arc.
 * @param {string} largeArcFlag - Flag for angles over 180 degrees.
 * @param {} x - X coordinate for arc.
 * @param {} y - Y coordinate for arc.
 */
function arc(
  width: number,
  radius: number,
  largeArcFlag: string,
  x: number,
  y: number
) {
  const z = (width / 2) * radius

  return `A${z}, ${z} 0 ${largeArcFlag} ${x}, ${y}`
}

/**
 * Creates the SVG path object for an item.
 * @param {} value - Value of incoming item.
 * @param {} total - Culmulative value of all items
 * @param {} startAngle - Degree at which the angle calculation should begin.
 * @param {} width - Total width of the chart.
 * @param {} innerRadius - Inner circle's radius.
 * @param {} outerRadius - Outer circle's radius.
 */
function path(
  activeAngle: number,
  startAngle: number,
  width: number,
  innerRadius: number,
  outerRadius: number
) {
  const endAngle = startAngle + activeAngle

  const largeArcFlagOuter = activeAngle > 180 ? '1 1' : '0 1'
  const largeArcFlagInner = activeAngle > 180 ? '1 0' : '0 0'
  const half = width / 2
  const outerCoords = coordinates(half, outerRadius, startAngle, endAngle)
  const innerCoords = coordinates(half, innerRadius, startAngle, endAngle)

  const outerArc = arc(
    width,
    outerRadius,
    largeArcFlagOuter,
    outerCoords.x2,
    outerCoords.y2
  )
  const innerArc = arc(
    width,
    innerRadius,
    largeArcFlagInner,
    innerCoords.x1,
    innerCoords.y1
  )

  return `M${outerCoords.x1},${outerCoords.y1}
    ${outerArc}
    L${innerCoords.x2},${innerCoords.y2}
    ${innerArc} z`
}

const defaultProps = {
  className: 'donutchart-arcs-path',
}

type DefaultProps = Readonly<typeof defaultProps>

type Props = {
  item: Item
  total: number
  angle: number
  width: number
  innerRadius: number
  outerRadius: number
  onMouseEnter: (item: Item) => void
  onMouseLeave: (item: Item) => void
  onClick: (item: Item) => void
  fill: Color
  stroke: Color
  opacity: number
} & Partial<DefaultProps>

export default class ArcPath extends Component<Props & DefaultProps> {
  render() {
    const {
      width,
      angle,
      total,
      fill,
      stroke,
      opacity,
      item,
      className,
      innerRadius,
      outerRadius,
      onClick,
      onMouseEnter,
      onMouseLeave,
    } = this.props
    const {value} = item
    const activeAngle =
      Number.isNaN(value / total) || total / value === 1
        ? 359.99
        : (value / total) * 360
    const d = path(activeAngle, angle, width, innerRadius, outerRadius)
    return (
      <path
        onClick={() => onClick(item)}
        onMouseEnter={() => onMouseEnter(item)}
        onMouseLeave={() => onMouseLeave(item)}
        className={className}
        d={d}
        stroke={stroke}
        fill={fill}
        opacity={opacity}
      ></path>
    )
  }
}
