import {IParameters, IParameter} from './types/simulation'

export const ParameterNames = {
  l: 'Number of ticks',
  r: 'Requests per tick',
  e: 'Maximum waiting time',
  a: 'Adoption rate',
  k: 'Env. benefits importance',
} as {[K in IParameter]: string}

export const PossibleParameters = {
  l: [10, 30, 60],
  r: [10, 50, 100, 200, 400],
  e: [1, 2, 5, 10],
  a: [20, 40, 60, 80, 100],
  k: [0.25, 0.5, 0.75, 1.0],
}

export const InitialSimulationParameters = {
  l: 30,
  r: 100,
  e: 5,
  a: 80,
  k: 0.75,
}

export const AnalysisParameters = {
  1: {
    l: 1440,
    r: 0,
    e: 5,
    a: 100,
    k: 1,
  },
  2: {
    l: 1440,
    r: 0,
    e: 5,
    a: 60,
    k: 0.5,
  },
  3: {
    l: 1440,
    r: 0,
    e: 5,
    a: 20,
    k: 0.25,
  },
} as {[k: number]: IParameters}
