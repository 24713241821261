import {call, put, takeLatest} from 'redux-saga/effects'
import {LoadAction, Actions} from './types'
import {fetchSimulation} from '../../services/api'
import * as creators from './actions'
import {namespaceCreators} from '../namespaces'

function loadSimulation(namespace: string) {
  const [loadDone, loadError] = namespaceCreators(namespace, [
    creators.loadDone,
    creators.loadError,
  ])

  return function*(action: LoadAction) {
    try {
      const simulation = yield call(fetchSimulation, action.parameters)
      yield put(loadDone(simulation))
    } catch (e) {
      yield put(loadError())
    }
  }
}

export default function(namespace: string) {
  return function*() {
    const load = loadSimulation(namespace)
    yield takeLatest(`${Actions.LOAD}:${namespace}`, load)
  }
}
