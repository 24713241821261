import React from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import Footer from '../../components/Footer/Footer'
import LoadingIcon from '../../components/Icons/LoadingIcon'
import Menu from '../../components/Menu/Menu'
import PageHeader from '../../components/PageHeader/PageHeader'
import ParameterSlider from '../../components/Simulation/Parameters/ParameterSlider'
import {ParameterNames} from '../../config'
import {AppState} from '../../store'
import styles from './Parameters.module.scss'
import ReactTooltip from 'react-tooltip'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const SimulationParameters = () => (
  <form className={styles.simulation_parameters}>
    <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="data-tip-l" />
    <label htmlFor="ticks">{ParameterNames.l}</label>
    <ParameterSlider name="l" />
    <ReactTooltip id="data-tip-l" type="info" effect="solid" className="react-tooltip">
      <p>Total number of simulation steps</p>
      <p>(time horizon)</p>
    </ReactTooltip>

    <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="data-tip-r" />
    <label htmlFor="requests">{ParameterNames.r}</label>
    <ParameterSlider name="r" />
    <ReactTooltip id="data-tip-r" type="info" effect="solid" className="react-tooltip">
      <p>Number of new requests that are delivered</p>
      <p>to the system at each new simulation tick</p>
    </ReactTooltip>

    <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="data-tip-e" />
    <label htmlFor="maximumWait">{ParameterNames.e}</label>
    <ParameterSlider name="e" />
    <ReactTooltip id="data-tip-e" type="info" effect="solid" className="react-tooltip">
      <p>Maximum number of simulation ticks that</p>
      <p>each request is willing to wait in the system</p>
      <p>before being assigned to a car</p>
      <p>(unserviced requests expire and exit the system)</p>
    </ReactTooltip>

    <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="data-tip-a" />
    <label htmlFor="adoptionRate">{ParameterNames.a}</label>
    <ParameterSlider tipFormatter={(t: number) => t + '%'} name="a" />
    <ReactTooltip id="data-tip-a" type="info" effect="solid" className="react-tooltip">
      <p>Percentage of requests that adopts ridesharing</p>
      <p>(the remaining part does not adopt ridesharing)</p>
    </ReactTooltip>

    <FontAwesomeIcon icon={faInfoCircle} data-tip data-for="data-tip-k" />
    <label htmlFor="envBenefit">{ParameterNames.k}</label>
    <ParameterSlider tipFormatter={(t: number) => t * 100 + '%'} name="k" />
    <ReactTooltip id="data-tip-k" type="info" effect="solid" className="react-tooltip">
      <p>Importance given to the optimisation of the environmental</p>
      <p>benefits with respect to the Quality of Service:</p>
      <p>larger values will result in higher CO₂ reductions,</p>
      <p>but users will experience a higher in-car delay</p>
    </ReactTooltip>
  </form>
)

const ISimulateButton = ({
  enabled,
  loading,
}: {
  enabled: boolean
  loading: boolean
}) => (
  <Link
    className={[
      styles.simulate,
      enabled ? styles.simulate_enabled : styles.simulate_disabled,
    ].join(' ')}
    to="/simulation/run"
  >
    <LoadingIcon mode="fixed" loading={loading} />
    Simulate
  </Link>
)
const SimulateButton = connect((state: AppState) => ({
  enabled: state.simulation.status === 'loaded',
  loading: state.simulation.status === 'loading',
}))(ISimulateButton)

const Parameters = () => (
  <div className={styles.wrapper}>
    <PageHeader variant="light" />
    <Menu />
    <div className={styles.hero}>
      <div>
        <h1>Simulation</h1>
        <p>
          The ridesharing simulation will be created according to the parameters selected below,
          following the data distribution from the <a href="https://www1.nyc.gov/site/tlc/about/tlc-trip-record-data.page" target="_blank" rel="noopener noreferrer">New York City TLC dataset</a>
        </p>
      </div>
    </div>
    <section className={styles.page}>
      <h2>Simulation parameters</h2>
      <SimulationParameters />
      <div className={styles.actions}>
        <SimulateButton />
      </div>
    </section>
    <Footer className={styles.footer} />
  </div>
)

export default Parameters
