import {ISimulation, IParameters} from '../../types/simulation'

export enum Actions {
  LOAD = 'SIMRESULTS:LOAD',
  LOAD_DONE = 'SIMRESULTS:LOAD:DONE',
  LOAD_ERROR = 'SIMRESULTS:LOAD:ERROR',
}

export interface ISimulationResultsState {
  status: 'initial' | 'loading' | 'error' | 'loaded'
  simulation: ISimulation | null
}

export interface LoadAction {
  type: typeof Actions.LOAD
  parameters: IParameters
}

export interface LoadDoneAction {
  type: typeof Actions.LOAD_DONE
  data: ISimulation
}

export interface LoadErrorAction {
  type: typeof Actions.LOAD_ERROR
}

export type SimulationActionTypes =
  | LoadAction
  | LoadDoneAction
  | LoadErrorAction
