import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Menu.module.scss'

const Menu = () => (
  <nav className={styles.menu}>
    <ul>
      <li>
        <NavLink to="/" activeClassName={styles.active} exact>
          Home
        </NavLink>
      </li>
      <li>
        <NavLink to="/simulation" activeClassName={styles.active}>
          Simulation
        </NavLink>
      </li>
      <li>
        <NavLink to="/analysis" activeClassName={styles.active}>
          Analysis
        </NavLink>
      </li>
    </ul>
  </nav>
)

export default Menu
