import {Color} from 'csstype'
import React, {Component} from 'react'
import Arcs from './Arcs'

function calculateTotal(items: any[]) {
  return items.reduce((sum, currItem) => sum + currItem.value, 0)
}

const initialSate = () => ({
  selected: {} as Item,
  toggleSelect: false,
})

const defaultProps = {
  className: 'donutchart',
  height: 100,
  width: 100,
  colors: ['#d8ebf7', '#a3bad4', '#7994b9', '#5a77a4', '#466597'] as Color[],
  strokeColor: '#212121' as Color,
  startAngle: 0,
  colorFunction: (colors: Color[], index: number) =>
    colors[index % colors.length],
  innerRadius: 0.7,
  outerRadius: 1,
  selectedOffset: 0.1,
  toggledOffset: 0.1,
  onMouseEnter: (item: Item) => {},
  onMouseLeave: (item: Item) => {},
  onClick: ((item: Item, toggled: boolean) => (toggled ? item : null)) as (
    i: Item,
    t: boolean
  ) => void,
  clickToggle: true,
}

type DefaultProps = Readonly<typeof defaultProps>

type Props = {
  data: Item[]
} & Partial<DefaultProps>

export default class DonutChart extends Component<Props & DefaultProps> {
  static defaultProps = defaultProps
  state = initialSate()

  componentWillReceiveProps(newProps: Props) {
    const {data} = newProps

    // if new data, reset
    if (data && JSON.stringify(data) !== JSON.stringify(this.props.data)) {
      this.setState(initialSate())
    }
  }

  handleClick = (item: Item) => {
    if (this.state.selected.label === item.label) {
      const toggle = this.props.clickToggle ? !this.state.toggleSelect : false
      this.setState({
        toggleSelect: toggle,
        selected: item,
      })
      this.props.onClick(item, toggle)
    }
  }

  handleMouseEnter = (item: Item) => {
    if (!this.state.toggleSelect) {
      this.setState({
        selected: item,
      })
      this.props.onMouseEnter(item)
    }
  }

  handleMouseLeave = (item: Item) => {
    if (!this.state.toggleSelect) {
      this.setState({
        selected: null,
      })
      this.props.onMouseLeave(item)
    }
  }

  render() {
    const {
      startAngle,
      width,
      height,
      className,
      data,
      strokeColor,
      colors,
      colorFunction,
      innerRadius,
      outerRadius,
      selectedOffset,
      toggledOffset,
    } = this.props

    const arcsClassName = `${className}-arcs`
    const total = calculateTotal(data)

    return (
      <svg className={className} viewBox={`0 0 ${width} ${height}`}>
        <Arcs
          className={arcsClassName}
          colors={colors}
          data={data}
          width={width}
          strokeColor={strokeColor}
          colorFunction={colorFunction}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onClick={this.handleClick}
          selected={this.state.selected}
          startAngle={startAngle}
          toggleSelect={this.state.toggleSelect}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          selectedOffset={selectedOffset}
          toggledOffset={toggledOffset}
          total={total}
        />
      </svg>
    )
  }
}
