import React from 'react'
import {Route} from 'react-router-dom'
import Summary from './Summary'
import Explore from './Explore'

const Analysis = () => {
  return (
    <>
      <Route exact path="/analysis" component={Summary} />
      <Route exact path="/analysis/explore" component={Explore} />
    </>
  )
}

export default Analysis
