export function namespaceCreators<A extends {type: string}>(
  namespace: string,
  actionCreators: ((...args: any[]) => A)[]
) {
  return actionCreators.map(creator => (...args: any[]) => {
    const action = creator(...args)
    return {
      ...action,
      type: `${action.type}:${namespace}`,
    }
  })
}
