import React from 'react'

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0

  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  }
}

function describeArc(
  x: number,
  y: number,
  radius: number,
  startAngle: number,
  endAngle: number
) {
  var start = polarToCartesian(x, y, radius, endAngle)
  var end = polarToCartesian(x, y, radius, startAngle)

  var largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1'

  var d = [
    'M',
    start.x,
    start.y,
    'A',
    radius,
    radius,
    0,
    largeArcFlag,
    0,
    end.x,
    end.y,
  ].join(' ')

  return d
}

interface Props {
  background: string
  color: string
  value: number
  className: string
}

const Gauge = ({ color, background, value, className }: Props) => {
  return (
    <svg viewBox="0 0 100 100">
      <circle
        cx="50"
        cy="50"
        strokeWidth="8"
        stroke={background}
        r="45"
        fill="none"
      />
      <path
        fill="none"
        d={describeArc(50, 50, 45, 0, 360 * value)}
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
      />
    </svg>
  )
}

export default Gauge
