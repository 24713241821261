import React, {useState, useMemo, Fragment} from 'react'
import Footer from '../../components/Footer/Footer'
import Menu from '../../components/Menu/Menu'
import PageHeader from '../../components/PageHeader/PageHeader'
import styles from './Explore.module.scss'
import Slider from '../../components/Slider/Slider'
import {Target} from '../../types/exploration'
import {data} from './data'
import {IParameter} from '../../types/simulation'
import {PossibleParameters} from '../../config'

type Combinations = [number, number][]

function midRange(target: Target) {
  return (data[target].min + data[target].max) / 2
}

function computeCombinations(
  target: Target,
  value: number,
  parameter: IParameter,
  parameterValue: number
) {
  const combinations: Combinations = []
  const ks =
    parameter === 'k'
      ? [PossibleParameters['k'].indexOf(parameterValue)]
      : PossibleParameters['k'].map((_v, i) => i)
  const avs =
    parameter === 'a'
      ? [PossibleParameters['a'].indexOf(parameterValue)]
      : PossibleParameters['a'].map((_v, i) => i)
  ks.forEach(k => {
    avs.forEach(a => {
      if (data[target].data[a][k] >= value) {
        combinations.push([a, k])
      }
    })
  })
  return combinations
}

function computeRange(target: Target, combinations: Combinations) {
  const values = combinations.map(([a, k]) => data[target].data[a][k])
  return [Math.min(...values), Math.max(...values)] as [number, number]
}

interface PolicySelector {
  parameter: IParameter
  setParameter: (parameter: IParameter) => void
  value: number
  setValue: (value: number) => void
}
const PolicySelector = ({
  parameter,
  setParameter,
  value,
  setValue,
}: PolicySelector) => {
  const values = PossibleParameters[parameter]
  return (
    <div>
      <div className={styles.select_subject}>
        <label htmlFor="parameter">Variable</label>
        <select
          name="parameter"
          value={parameter}
          onChange={e => {
            const param = e.target.value as IParameter
            setParameter(param)
            setValue(PossibleParameters[param][0])
          }}
        >
          {['a', 'k'].map(v => {
            return (
              <option key={v} value={v}>
                {v === 'a' ? 'Adoption Rate' : 'Env. Benefits Importance'}
              </option>
            )
          })}
        </select>
      </div>
      <Slider
        onChange={v => setValue(v)}
        tipProps={{
          visible: true,
        }}
        included={false}
        min={values[0]}
        max={values[values.length - 1]}
        value={value}
        marks={values.reduce(
          (map, v) => {
            map[v as number] = ''
            return map
          },
          {} as any
        )}
        step={null}
      />
    </div>
  )
}

interface TargetSelectorProps {
  target: Target
  value: number
  setTarget: (target: Target) => void
  setValue: (value: number) => void
}
const TargetSelector = ({
  target,
  setTarget,
  value,
  setValue,
}: TargetSelectorProps) => {
  return (
    <div>
      <div className={styles.select_subject}>
        <label htmlFor="target">Target</label>
        <select
          name="target"
          value={target}
          onChange={e => {
            const t = e.target.value as Target
            setTarget(t)
            setValue(midRange(t))
          }}
        >
          {Object.entries(Target).map(([k, v]) => {
            return (
              <option key={k} value={Target[k as any]}>
                {v}
              </option>
            )
          })}
        </select>
      </div>
      <Slider
        tipProps={{
          visible: true,
        }}
        included={false}
        onChange={v => setValue(v)}
        value={value}
        min={data[target].min}
        max={data[target].max}
        step={0.01}
      />
    </div>
  )
}

interface ValueRangesProps {
  target: Target
  ranges: {[k: string]: [number, number]}
}
const ValueRanges = ({target, ranges}: ValueRangesProps) => {
  return (
    <div className={styles.outcomes}>
      {Object.keys(Target)
        .filter(k => Target[k as any] !== target)
        .map(k => {
          const kk = Target[k as any] as Target
          return (
            <Fragment key={k}>
              <div>{Target[k as any]}</div>
              <ValueRange
                full={[data[kk].min, data[kk].max]}
                active={ranges[k]}
              />
            </Fragment>
          )
        })}
    </div>
  )
}

interface ValueRangeProps {
  full: [number, number]
  active: [number, number]
}
const ValueRange = ({full, active}: ValueRangeProps) => {
  const total = full[1] - full[0]
  const left = (100 * (active[0] - full[0])) / total
  const right = (100 * (full[1] - active[1])) / total

  return (
    <div className={styles.range}>
      {Number.isFinite(active[0]) && (
        <>
          <div
            className={styles.range_active}
            style={{left: `${left}%`, right: `${right}%`}}
          />
          <div className={styles.range_marker_left} style={{left: `${left}%`}}>
            {active[0]}
          </div>
          <div
            className={styles.range_marker_right}
            style={{right: `${right}%`}}
          >
            {active[1]}
          </div>
        </>
      )}
    </div>
  )
}

const Playground = () => {
  const [parameter, setParameter] = useState('k' as IParameter)
  const [parameterValue, setParameterValue] = useState(0.25)
  const [target, setTarget] = useState(Target.average_occupancy)
  const [value, setValue] = useState(midRange(Target.average_occupancy))

  const ranges = useMemo(() => {
    const combinations = computeCombinations(
      target,
      value,
      parameter,
      parameterValue
    )
    const ranges: {[k: string]: [number, number]} = {}
    Object.keys(Target)
      .filter(k => k !== target)
      .forEach(k => {
        ranges[k] = computeRange(Target[k as any] as Target, combinations)
      })
    return ranges
  }, [target, value, parameter, parameterValue])

  return (
    <>
      <div className={styles.hero}>
        <div>
          <h1>Explore</h1>
          <p>
            Create a custom scenario by setting a personalised objective (in terms of CO₂
            reduction, trips reduction, in-car delay, etc) and explore the feasibility zone
            in terms of adoption rate and/or environmental benefit importance required to
            achieve such objective
          </p>
        </div>
      </div>
      <section className={styles.page}>
        <div className={styles.selectors}>
          <PolicySelector
            parameter={parameter}
            setParameter={setParameter}
            value={parameterValue}
            setValue={setParameterValue}
          />
          <TargetSelector
            target={target}
            setTarget={setTarget}
            value={value}
            setValue={setValue}
          />
        </div>
        <h2>Outcomes</h2>
        <ValueRanges ranges={ranges} target={target} />
      </section>
    </>
  )
}

const Explore = () => {
  return (
    <div className={styles.wrapper}>
      <PageHeader variant="light" />
      <Menu />
      <Playground />
      <Footer className={styles.footer} />
    </div>
  )
}

export default Explore
