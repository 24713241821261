import {ISimulation, IParameters} from '../../types/simulation'
import {Actions, SimulationActionTypes} from './types'

export function load(parameters: IParameters): SimulationActionTypes {
  return {
    type: Actions.LOAD,
    parameters,
  }
}

export function loadDone(data: ISimulation): SimulationActionTypes {
  return {
    type: Actions.LOAD_DONE,
    data,
  }
}

export function loadError(): SimulationActionTypes {
  return {
    type: Actions.LOAD_ERROR,
  }
}
